import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment coachCard_UserFragment on CoachProfile {
		id
		coach {
			name
			extension {
				... on UserExtensionImpl {
					position
					firstName
					lastName
				}
			}
			...userAvatar_UserFragment
		}
		profileData {
			description
			avatar {
				url
			}
			jobTitles
		}
	}
`;
