import { graphql } from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment profileTab_UserFragment on User {
		extension {
			... on UserExtensionImpl {
				firstName
				lastName
			}
		}
		...userAvatar_UserFragment
	}
`;
export const COACH_PROFILE_FRAGMENT = graphql`
	fragment profileTab_CoachProfileFragment on CoachProfile {
		profileData {
			socials {
				linkedIn
				facebook
				twitter
				website
				others
			}
			description
			jobTitles
			quote
			avatar {
				id
				url
				thumbnail
			}
			basicPersonal {
				lastName
				firstName
				title
				salutation
				phoneNumber
				email
			}
			certificateAnswer
			certificateFileIds {
				id
				name
			}
			coachingStrongPointsTopics
			digitalAffinity1
			digitalAffinity2
			educationFreeText
			educationMoreThan150h
			languages
			methods
			personalPhilosophyCoachingStyle
			personalPhilosophyVisionMission
			referencesPublications
			referencesCoachingExperienceIds {
				id
				name
			}
			studies
			experience {
				branches
				branchesSteps
				coachingFileIds {
					id
					name
				}
				coachingHours
			}
		}
	}
`;

export const EDIT_MY_PROFILE_DATA_MUTATION = graphql`
	mutation profileTab_editMyProfileDataMutation($input: EditMyProfileDataInput!) {
		Coaching {
			editMyProfileData(input: $input) {
				coachProfile {
					id
					...profileTab_CoachProfileFragment
					...personnelSheetTab_CoachProfileFragment
				}
			}
		}
	}
`;
