import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP3Span } from "@themes/font-tags";
import {
	PasswordResetPasswordFormState,
	PasswordResetPasswordStepProps,
} from "./password-reset-password-step.interface";
import { FormWrapper, InputsWrapper, TextCenter } from "./password-reset-password-step.styles";

export const PasswordResetPasswordStep = ({
	onGoToLogin,
	onNext,
}: PasswordResetPasswordStepProps) => {
	const { t } = useTkaTranslation("resetPasswordForm");

	const form = useFormik<PasswordResetPasswordFormState>({
		initialValues: {
			password: "",
			passwordRepeat: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.min(8, t("reset_password_form.validationInvalidPassword"))
				.required(t("reset_password_form.validationRequiredPassword")),
			passwordRepeat: Yup.string().oneOf(
				[Yup.ref("password"), null],
				t("reset_password_form.validationInvalidPasswordDuplicate"),
			),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				<TextCenter>
					<H1Span>{t("reset_password_form.title")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="password"
						type="password"
						status="default"
						icon="lock"
						placeholder={t("reset_password_form.placeholderPassword")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="passwordRepeat"
						type="password"
						status="default"
						icon="lock"
						placeholder={t("reset_password_form.placeholderPasswordDuplicate")}
					/>
				</InputsWrapper>
				<Button fillParent label={t("reset_password_form.buttonText")} />
				<TextCenter>
					<TkaP3Span>
						{t("reset_password_form.rememberPassword")}
						&nbsp;
						<TkaP3Span tkaColor={brandStrong100} onClick={onGoToLogin}>
							{t("reset_password_form.backToLogin")}
						</TkaP3Span>
					</TkaP3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
