import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AccountsDropdown } from "@components/accounts-dropdown";
import { Button } from "@components/button";
import { DividerList } from "@components/divider-list";
import { DualRowWrapper } from "@components/profile-menu-row/profile-menu-row.styled";
import { ReportModalFlow } from "@components/report-modal-flow/report-modal-flow.component";
import { HTMLMouseEvent } from "@customTypes/index";
import { useAuthContext } from "@hooks/use-auth-context";
import { usePermissions } from "@hooks/use-permissions";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { profileMenu_AuthViewerSchemaFragment$key } from "@relay/profileMenu_AuthViewerSchemaFragment.graphql";
import { profileMenu_Query } from "@relay/profileMenu_Query.graphql";
import { Path } from "@router/paths";
import { selectIsAvgsClient } from "@slices/AuthSlice";
import { shade0 } from "@themes/colors";
import { H1Span, TkaH3Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, QUERY } from "./profile-menu.graphql";
import { ProfileMenuSkeleton } from "./profile-menu.skeleton";
import {
	AvatarWrapper,
	CurrentUserWrapper,
	MenuWrapper,
	PointsWrapper,
} from "./profile-menu.styled";
import { ProfileMenuProps } from "./profile-menu.types";
import { Chip } from "../chip";
import { Icon } from "../icon";
import { ProfileMenuRow } from "../profile-menu-row";
import { UserAvatar } from "../user-avatar";
import { withSuspense } from "../with-suspense/with-suspense.component";

// TODO: add-translations
export const ProfileMenuComponent = ({
	onGrayBackground,
	onNavigated,
	onClose,
}: ProfileMenuProps) => {
	const query = useLazyLoadQuery<profileMenu_Query>(QUERY, {});

	const auth = useFragment<profileMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const isAvgsClient = useSelector(selectIsAvgsClient);
	const navigate = useNavigate();
	const { t } = useTkaTranslation("navbar");
	const { logout } = useAuthContext();
	const { isCoachAccount } = usePermissions();
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);
	const accounts = auth?.currentUser?.accounts ?? [];

	const handleLogoutOnClick = (event: HTMLMouseEvent) => {
		logout();
		onNavigated?.(event);
	};
	const handleCreateReportOnClick = (_: HTMLMouseEvent) => {
		setIsShowingCreateReportFlow(true);
	};
	const handleDismissModalFlowOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};

	const handleProfileOnClick = (event: HTMLMouseEvent) => {
		navigate(Path.profileMenu.profile.path);
		onNavigated?.(event);
	};

	const createMenuItemOnClickHandler = (path: string) => (event: HTMLMouseEvent) => {
		navigate(path);
		onNavigated?.(event);
	};

	const handleOnAccountSelected = () => {
		onClose?.();
	};

	const name = auth?.currentUser?.user?.name;
	const hasMoreThenOneAccount = accounts.length > 1;
	const points = auth?.currentUser?.user.extension.sumGamificationPoints ?? 0;
	const chipBackgroundColor = onGrayBackground ? shade0 : undefined;

	return (
		<Fragment>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleDismissModalFlowOnClick}
			/>
			<MenuWrapper>
				{name && (
					<CurrentUserWrapper>
						<AvatarWrapper>
							<UserAvatar userFragmentRef={auth.currentUser.user} sizeInRem={5} />
							{!isAvgsClient && (
								<PointsWrapper>
									<Chip backgroundColor={chipBackgroundColor}>
										<TkaH3Span>{points}</TkaH3Span>
										<Icon icon="coinStar" sizeInRem={1} />
									</Chip>
								</PointsWrapper>
							)}
						</AvatarWrapper>
						<H1Span>{name}</H1Span>
					</CurrentUserWrapper>
				)}
				{isCoachAccount && (
					<Button label="Mein Profil" fillParent onClick={handleProfileOnClick} />
				)}
				<DividerList hasDivider gapRem={1}>
					{hasMoreThenOneAccount && auth?.currentUser && (
						<AccountsDropdown
							label="Team"
							onAccountSelected={handleOnAccountSelected}
							currentUserFragmentRef={auth.currentUser}
						/>
					)}

					{!isAvgsClient && (
						<ProfileMenuRow
							icon="calendar"
							label="Meine Termine"
							onClick={createMenuItemOnClickHandler(Path.appointments.path)}
						/>
					)}
					{!isAvgsClient && (
						<ProfileMenuRow
							icon="translate"
							label="Vokabeln"
							onClick={createMenuItemOnClickHandler(Path.profileMenu.vocabulary.path)}
						/>
					)}
					{isCoachAccount && !isAvgsClient && (
						<ProfileMenuRow
							icon="calendarCheck"
							label="Meine Verfügbarkeiten"
							onClick={createMenuItemOnClickHandler(
								Path.profileMenu.myAvailabilities.path,
							)}
						/>
					)}
					{isCoachAccount && !isAvgsClient && (
						<ProfileMenuRow
							icon="coachingTeam"
							label="Meine Coaching-Angebote"
							onClick={createMenuItemOnClickHandler(
								Path.profileMenu.myCoachingOffers.path,
							)}
						/>
					)}
					{!isAvgsClient && (
						<ProfileMenuRow
							icon="loginKey"
							label="Lizenzen"
							onClick={createMenuItemOnClickHandler(Path.profileMenu.licenses.path)}
						/>
					)}
					{!isAvgsClient && (
						<ProfileMenuRow
							icon="book"
							label="Dokumente"
							onClick={createMenuItemOnClickHandler(Path.documents.path)}
						/>
					)}
					{!isAvgsClient && (
						<ProfileMenuRow
							icon="moneyWallet"
							label="Konto"
							onClick={createMenuItemOnClickHandler(Path.profileMenu.account.path)}
						/>
					)}
					<ProfileMenuRow
						icon="materialComplete"
						label="Impressum"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.imprint.path)}
					/>
					<DualRowWrapper>
						<ProfileMenuRow
							icon={"question"}
							label={"Inhalte melden & Feedback"}
							onClick={handleCreateReportOnClick}
							isShort
						/>
						<Button
							colorVersion={"error"}
							label={t("navbar.logout")}
							onClick={handleLogoutOnClick}
							iconName={"logout"}
						/>
					</DualRowWrapper>
				</DividerList>
			</MenuWrapper>
		</Fragment>
	);
};

export const ProfileMenu = withSuspense(ProfileMenuComponent, ProfileMenuSkeleton);
