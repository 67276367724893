import { graphql } from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment userAvatar_UserFragment on User {
		extension {
			... on UserExtensionImpl {
				firstName
				lastName
				avatar {
					thumbnail
				}
			}
		}
	}
`;
