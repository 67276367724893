import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment socialsCard_CoachProfileFragment on CoachProfile {
		id
		coach {
			name
			email
			extension {
				... on UserExtensionImpl {
					firstName
					lastName
				}
			}
			...userAvatar_UserFragment
		}
		profileData {
			avatar {
				url
			}
			description
			jobTitles
			socials {
				others
				linkedIn
				facebook
				twitter
				website
			}
		}
	}
`;
