import { useFragment } from "react-relay";
import { Avatar } from "@components/avatar";
import { userAvatar_UserFragment$key } from "@relay/userAvatar_UserFragment.graphql";
import { USER_FRAGMENT } from "./user-avatar.graphql";
import { UserAvatarProps } from "./user-avatar.types";

export const UserAvatar = ({ sizeInRem, userFragmentRef }: UserAvatarProps) => {
	const user = useFragment<userAvatar_UserFragment$key>(USER_FRAGMENT, userFragmentRef);

	return (
		<Avatar
			sizeInRem={sizeInRem}
			firstName={user.extension.firstName}
			lastName={user.extension.lastName}
			imgURL={user.extension.avatar?.thumbnail}
		/>
	);
};
