/**
 * @generated SignedSource<<33b850f32fe536bfce3482f884e211c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointmentOverviewModal_Query$variables = {
  filterByGroupIds: ReadonlyArray<string>;
};
export type appointmentOverviewModal_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"appointmentDetails_QueryFragment">;
};
export type appointmentOverviewModal_Query = {
  response: appointmentOverviewModal_Query$data;
  variables: appointmentOverviewModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByGroupIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterByGroupIds",
    "variableName": "filterByGroupIds"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "appointmentOverviewModal_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "appointmentDetails_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "appointmentOverviewModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountMembersResult",
            "kind": "LinkedField",
            "name": "AccountMembers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AccountMemberConnection",
                "kind": "LinkedField",
                "name": "accountMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserInAccount",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "extension",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "File",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "thumbnail",
                                                    "storageKey": null
                                                  },
                                                  (v4/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "UserExtensionImpl",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "UserInAccountWrapper",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "accountMembers(first:100)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "inputUser_accountMembers",
                "kind": "LinkedHandle",
                "name": "accountMembers"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bac28fb837a08ff7a0d6f25f6e62449b",
    "id": null,
    "metadata": {},
    "name": "appointmentOverviewModal_Query",
    "operationKind": "query",
    "text": "query appointmentOverviewModal_Query(\n  $filterByGroupIds: [ID!]!\n) {\n  ...appointmentDetails_QueryFragment_1gDN6I\n}\n\nfragment appointmentDetails_QueryFragment_1gDN6I on Query {\n  ...inputUser_QueryFragment_1gDN6I\n}\n\nfragment inputSuggestionUserItemTemplate_UserFragment on User {\n  id\n  name\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      avatar {\n        thumbnail\n        id\n      }\n    }\n  }\n}\n\nfragment inputUser_QueryFragment_1gDN6I on Query {\n  AccountMemberManagement {\n    AccountMembers(filterByGroupIds: $filterByGroupIds) {\n      accountMembers(first: 100) {\n        edges {\n          node {\n            __typename\n            id\n            ... on UserInAccountWrapper {\n              user {\n                user {\n                  id\n                  ...inputSuggestionUserItemTemplate_UserFragment\n                }\n                id\n              }\n            }\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8fb10bf65869462912b1f05ce35f1ea";

export default node;
