/**
 * @generated SignedSource<<899b795be4613d8ac6be408a2666cf43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileTab_UserFragment$data = {
  readonly extension: {
    readonly firstName?: string;
    readonly lastName?: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "profileTab_UserFragment";
};
export type profileTab_UserFragment$key = {
  readonly " $data"?: profileTab_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileTab_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileTab_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "type": "UserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userAvatar_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b9d10ed067f2cfe0021059ba677c35f7";

export default node;
