import { useFragment } from "react-relay";
import { TkaP2Span } from "@themes/font-tags";
import { USER_FRAGMENT } from "./input-suggestion-user-item-template.graphql";
import { Wrapper } from "./input-suggestion-user-item-template.styles";
import { InputSuggestionUserItemTemplateProps } from "./input-suggestion-user-item-template.types";
import { Avatar } from "../avatar";

export const InputSuggestionUserItemTemplate = ({
	userFragmentRef,
}: InputSuggestionUserItemTemplateProps) => {
	const user = useFragment(USER_FRAGMENT, userFragmentRef ?? null);
	if (!user) return null;

	return (
		<Wrapper>
			<Avatar sizeInRem={1.5} name={user.name} imgURL={user.extension.avatar?.thumbnail} />
			<TkaP2Span>{user.name}</TkaP2Span>
		</Wrapper>
	);
};
