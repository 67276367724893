import React from "react";
import { useFragment } from "react-relay";
import { Avatar } from "@components/avatar";
import { SocialsButton } from "@components/socials-button";
import { socialsCard_CoachProfileFragment$key } from "@relay/socialsCard_CoachProfileFragment.graphql";
import { COACH_PROFILE_FRAGMENT } from "@screens/coach/parts/socials-card/socials-card.graphql";
import {
	Flex,
	SocialsWrapper,
	VerticalFlex,
	Wrapper,
} from "@screens/coach/parts/socials-card/socials-card.styles";
import { shade100 } from "@themes/colors";
import { H1Span, TkaP1Span } from "@themes/font-tags";
import { SocialsCardProps } from "./socials-card.types";

export const SocialsCard = ({ coachProfileFragmentRef }: SocialsCardProps) => {
	const coachProfile = useFragment<socialsCard_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);

	const createOnClickHandler = (link?: string) => () => {
		if (!link) return;
		window.open(link, "_blank");
	};
	const facebookLink = coachProfile.profileData.socials?.facebook;
	const linkedInLink = coachProfile.profileData.socials?.linkedIn;
	const twitterLink = coachProfile.profileData.socials?.twitter;
	const websiteLink = coachProfile.profileData.socials?.website;

	return (
		<Wrapper>
			<Flex>
				<Avatar
					sizeInRem={7.5}
					imgURL={coachProfile.profileData.avatar?.url}
					firstName={coachProfile.coach?.extension.firstName}
					lastName={coachProfile.coach?.extension.lastName}
				/>
				<VerticalFlex>
					<H1Span>{coachProfile.coach?.name}</H1Span>
					<TkaP1Span tkaColor={shade100}>
						{[...(coachProfile.profileData.jobTitles ?? [])].shift()}
					</TkaP1Span>
					<SocialsWrapper>
						{facebookLink && (
							<SocialsButton
								icon={"facebook"}
								onClick={createOnClickHandler(facebookLink)}
							/>
						)}
						{twitterLink && (
							<SocialsButton
								icon={"twitter"}
								onClick={createOnClickHandler(twitterLink)}
							/>
						)}
						{linkedInLink && (
							<SocialsButton
								icon={"linkedIn"}
								onClick={createOnClickHandler(linkedInLink)}
							/>
						)}
						{websiteLink && (
							<SocialsButton
								icon={"website"}
								onClick={createOnClickHandler(websiteLink)}
							/>
						)}
					</SocialsWrapper>
				</VerticalFlex>
			</Flex>
		</Wrapper>
	);
};
