/**
 * @generated SignedSource<<1c23c44ddd17b28f171a8acb91128159>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type socialsCard_CoachProfileFragment$data = {
  readonly coach: {
    readonly email: string;
    readonly extension: {
      readonly firstName?: string;
      readonly lastName?: string;
    };
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  } | null;
  readonly id: string;
  readonly profileData: {
    readonly avatar: {
      readonly url: string | null;
    } | null;
    readonly description: string | null;
    readonly jobTitles: ReadonlyArray<string> | null;
    readonly socials: {
      readonly facebook: string | null;
      readonly linkedIn: string | null;
      readonly others: ReadonlyArray<string> | null;
      readonly twitter: string | null;
      readonly website: string | null;
    } | null;
  };
  readonly " $fragmentType": "socialsCard_CoachProfileFragment";
};
export type socialsCard_CoachProfileFragment$key = {
  readonly " $data"?: socialsCard_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"socialsCard_CoachProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "socialsCard_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "type": "UserExtensionImpl",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userAvatar_UserFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileData",
      "kind": "LinkedField",
      "name": "profileData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataSocials",
          "kind": "LinkedField",
          "name": "socials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "others",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkedIn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facebook",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};

(node as any).hash = "0ccb14d65a461e1ff5854416b224647b";

export default node;
