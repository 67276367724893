/**
 * @generated SignedSource<<ca78cb80709406f4b80b2c89c6f75073>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userAvatar_UserFragment$data = {
  readonly extension: {
    readonly avatar?: {
      readonly thumbnail: string | null;
    } | null;
    readonly firstName?: string;
    readonly lastName?: string;
  };
  readonly " $fragmentType": "userAvatar_UserFragment";
};
export type userAvatar_UserFragment$key = {
  readonly " $data"?: userAvatar_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userAvatar_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "04b3edc99860f57d7c92ec858636d59e";

export default node;
