/**
 * @generated SignedSource<<9c1d6bb55d40e879bd53f4669f9b8d15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inputUser_QueryFragmentRefetch$variables = {
  after?: string | null;
  filterByGroupIds: ReadonlyArray<string>;
  filterByNameOrEmail?: string | null;
  first?: number | null;
};
export type inputUser_QueryFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"inputUser_QueryFragment">;
};
export type inputUser_QueryFragmentRefetch = {
  response: inputUser_QueryFragmentRefetch$data;
  variables: inputUser_QueryFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByGroupIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByNameOrEmail"
  },
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filterByGroupIds",
  "variableName": "filterByGroupIds"
},
v3 = {
  "kind": "Variable",
  "name": "filterByNameOrEmail",
  "variableName": "filterByNameOrEmail"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = [
  (v1/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "inputUser_QueryFragmentRefetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "inputUser_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "inputUser_QueryFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "concreteType": "AccountMembersResult",
            "kind": "LinkedField",
            "name": "AccountMembers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "AccountMemberConnection",
                "kind": "LinkedField",
                "name": "accountMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserInAccount",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "extension",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "File",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "thumbnail",
                                                    "storageKey": null
                                                  },
                                                  (v7/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "UserExtensionImpl",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "UserInAccountWrapper",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "inputUser_accountMembers",
                "kind": "LinkedHandle",
                "name": "accountMembers"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "631490cb638638049930773b084c276c",
    "id": null,
    "metadata": {},
    "name": "inputUser_QueryFragmentRefetch",
    "operationKind": "query",
    "text": "query inputUser_QueryFragmentRefetch(\n  $after: String\n  $filterByGroupIds: [ID!]!\n  $filterByNameOrEmail: String\n  $first: Int = 100\n) {\n  ...inputUser_QueryFragment_3iaN21\n}\n\nfragment inputSuggestionUserItemTemplate_UserFragment on User {\n  id\n  name\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      avatar {\n        thumbnail\n        id\n      }\n    }\n  }\n}\n\nfragment inputUser_QueryFragment_3iaN21 on Query {\n  AccountMemberManagement {\n    AccountMembers(filterByNameOrEmail: $filterByNameOrEmail, filterByGroupIds: $filterByGroupIds) {\n      accountMembers(first: $first, after: $after) {\n        edges {\n          node {\n            __typename\n            id\n            ... on UserInAccountWrapper {\n              user {\n                user {\n                  id\n                  ...inputSuggestionUserItemTemplate_UserFragment\n                }\n                id\n              }\n            }\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c0f1ff5d96a6e1f089a0e0366dd363c";

export default node;
