/**
 * @generated SignedSource<<4574c52033cdb2ac7f0e7610e12efc28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboard_points$data = {
  readonly sumGamificationPoints: number;
  readonly " $fragmentType": "dashboard_points";
};
export type dashboard_points$key = {
  readonly " $data"?: dashboard_points$data;
  readonly " $fragmentSpreads": FragmentRefs<"dashboard_points">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dashboard_points",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumGamificationPoints",
      "storageKey": null
    }
  ],
  "type": "UserExtensionImpl",
  "abstractKey": null
};

(node as any).hash = "d7205d0b5143761a8770b1787f4a91eb";

export default node;
